var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mt-0 pa-0"},[_c('div',{staticClass:"w-100 d-flex align-center justify-space-between pt-2 pb-3"},[_c('div',{staticClass:"d-flex align-center"},[(_vm.tipo !== 'year')?_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-left")])],1):_vm._e(),(_vm.tipo !== 'year')?_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.tipo !== 'year')?_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")])],1):_vm._e(),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"outlined":"","color":"grey darken-2"}},on),[_c('span',[_vm._v(_vm._s(_vm.typeToLabel[_vm.tipo]))]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.tipo = 'day';
                _vm.type = 'day';}}},[_c('v-list-item-title',[_vm._v("Días")])],1),_c('v-list-item',{on:{"click":function($event){_vm.tipo = 'week';
                _vm.type = 'week';}}},[_c('v-list-item-title',[_vm._v("Semana")])],1),_c('v-list-item',{on:{"click":function($event){_vm.tipo = 'month';
                _vm.type = 'month';}}},[_c('v-list-item-title',[_vm._v("Mes")])],1),_c('v-list-item',{on:{"click":function($event){_vm.tipo = 'year'}}},[_c('v-list-item-title',[_vm._v("Año")])],1)],1)],1)],1)]),(_vm.tipo !== 'year')?_c('v-sheet',{attrs:{"height":"600"}},[_c('v-calendar',{ref:"calendar",attrs:{"color":"primary","events":_vm.events,"event-color":_vm.getEventColor,"now":_vm.today,"type":_vm.type,"locale":"es-es","event-more-text":"mostrar más"},on:{"click:event":_vm.showEvent,"click:more":_vm.viewDay,"click:date":_vm.viewDay,"change":_vm.updateRange},model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-x":""},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c('v-card',{attrs:{"color":"grey lighten-4","min-width":"350px","flat":""}},[_c('v-toolbar',{attrs:{"dense":"","color":_vm.selectedEvent.color,"dark":""}},[_c('v-toolbar-title',{domProps:{"innerHTML":_vm._s(_vm.selectedEvent.name)}}),_c('v-spacer'),_c('v-tooltip',{directives:[{name:"auth-acl",rawName:"v-auth-acl",value:('gh-cal-act_reu-sendnotification'),expression:"'gh-cal-act_reu-sendnotification'"}],attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.notificar(_vm.selectedEvent)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-email")])],1)]}}],null,false,29183372)},[_c('span',[_vm._v("Notificar")])]),_c('v-tooltip',{directives:[{name:"auth-acl",rawName:"v-auth-acl",value:('gh-act-mis_actas-view'),expression:"'gh-act-mis_actas-view'"}],attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.viewActa(_vm.selectedEvent)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,false,1171464992)},[_c('span',[_vm._v("Ver Acta")])]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.selectedOpen = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('h4',[_vm._v(_vm._s('N° Acta: ' + _vm.selectedEvent.numeroacta))]),_c('h4',[_vm._v(_vm._s('Sub-Titulo: ' + _vm.selectedEvent.subtitulo))]),_c('h4',[_vm._v(_vm._s('Título: ' + _vm.selectedEvent.titulo))]),(_vm.selectedEvent.responsables != undefined ? _vm.selectedEvent.responsables.length > 0 : false)?_c('div',[_c('div',{staticClass:"d-flex ma-2"},[_c('v-icon',{staticClass:" mr-2",attrs:{"small":""}},[_vm._v("fas fa-users")]),_c('h4',{staticClass:"font-weight-bold"},[_vm._v("RESPONSABLES")])],1),_vm._l((_vm.selectedEvent.responsables),function(responsables,i){return _c('ul',{key:i},[_c('li',[_vm._v(" "+_vm._s(responsables.nombres + ' - ' + responsables.idtrabajador)+" ")])])})],2):_vm._e()])],1)],1)],1):_vm._e(),(_vm.tipo === 'year')?_c('v-sheet',{attrs:{"height":"700"}},[_c('Calendar',{attrs:{"id":"calendar","language":"es","data-source":_vm.events,"display-week-number":true,"min-date":new Date(_vm.currentYear - 1, 12, 1),"max-date":new Date(_vm.currentYear, 11, 31)},on:{"mouse-on-day":_vm.mouseOnDay,"mouse-out-day":_vm.mouseOutDay,"click-day":_vm.viewDay}})],1):_vm._e()],1),(_vm.showLoading)?_c('div',{staticClass:"progress-background"},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"primary","indeterminate":""}})],1):_vm._e(),_c('ActasView',{directives:[{name:"auth-acl",rawName:"v-auth-acl",value:('gh-act-mat_aac-view'),expression:"'gh-act-mat_aac-view'"}],attrs:{"object":_vm.objectViewActa,"dialog":_vm.dialogActasView},on:{"closeActasView":_vm.closeActasView}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }