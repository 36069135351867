class CompromisoService {
  constructor() {}
  resources = () => ({
    compromisos: {
      uri: `/v1/calendario/compromisosxvencer`,
      method: ['get']
    },
    sendemail: {
      uri: `/v1/notificate/actas`,
      method: ['post'],
      headers: { 'Content-Type': 'application/json' }
    },
    viewacta: {
      uri: `/v1/actas/detalle`,
      method: ['get']
    },
    descargaracta: {
      uri: `/v1/actas/download`,
      method: ['get']
    }
  });
}

export default CompromisoService;
